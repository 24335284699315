<template>
  <div class="finance-item-detail" :class="[isPage ? 'container' : '']">
    <div v-if="isPage">
      <h1>{{ item?.name }}</h1>
      <div class="col money" :class="{ income: item?.totalInHuf > 0 }">
        {{
          item?.totalInHuf?.toLocaleString("hu-HU", {
            maximumFractionDigits: 0,
          })
        }}
        <span class="currency">Ft</span>
        <div class="sub" v-if="item?.currency !== 'HUF'">
          {{ item?.total }} {{ item?.currency }}
        </div>
      </div>
    </div>
    <div class="row page-content">
      <section class="col-12 show" :class="[isPage ? ' col-lg-6' : '']">
        <div class="box-container">
          <h3>Tranzakció</h3>
          <div :class="[isPage ? 'white-box py-3' : 'box-row']">
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Összeg</div>
              <div>{{ item?.total }} {{ item?.currency }}</div>
            </div>
            <div class="d-flex px-3 py-2" v-if="item?.tax">
              <div class="flex-1-1-0">ÁFA</div>
              <div>{{ item?.tax }} {{ item?.currency }}</div>
            </div>
            <div class="d-flex px-3 py-2" v-if="item?.vattype">
              <div class="flex-1-1-0">ÁFA típusa</div>
              <div>
                {{ item?.vattype }}
              </div>
            </div>
            <div class="d-flex px-3 py-2" v-if="item?.subtotal">
              <div class="flex-1-1-0">Bruttó</div>
              <div>{{ item?.subtotal }} {{ item?.currency }}</div>
            </div>
          </div>
        </div>
        <div class="box-container">
          <h3>Tranzakció részletei</h3>
          <div :class="[isPage ? 'white-box py-3' : 'box-row']">
            <div class="d-flex px-3 py-2" v-if="item?.BusinessName">
              <div class="flex-1-1-0">Vállalkozó</div>
              <div>
                {{ item?.BusinessName }}
              </div>
            </div>
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Csoport</div>
              <div>
                {{ item?.groupName }}
              </div>
            </div>
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Kategória</div>
              <div>
                {{ item?.category }}
              </div>
            </div>
            <div class="d-flex px-3 py-2" v-if="item?.BusinessName">
              <div class="flex-1-1-0">Számla sorszáma</div>
              <div>
                {{ item?.invoiceNumber }}
                <input
                  type="text"
                  v-if="showInvoiceNumber"
                  v-model="invoiceNumber"
                />
                <button
                  v-if="showInvoiceNumber"
                  v-on:click="addInvoiceNumber()"
                >
                  <BaseIcon name="plus" />
                </button>
                <button
                  v-else-if="!item?.invoiceNumber"
                  v-on:click="showInvoiceNumber = true"
                >
                  <BaseIcon name="pencil" />
                </button>
              </div>
            </div>
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Szolgáltatás azonosítója</div>
              <div>
                {{ item?.serviceid }}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="col-12 show" :class="[isPage ? ' col-lg-6' : '']">
        <div class="box-container" v-if="item.client">
          <h3>Kliens</h3>
          <div :class="[isPage ? 'white-box py-3' : 'box-row']">
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Kliens neve</div>
              <div>
                {{ item?.client }}
              </div>
            </div>
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Adószáma</div>
              <div>
                {{ item?.taxNumber }}
              </div>
            </div>
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Ország</div>
              <div>
                {{ item?.country }}
              </div>
            </div>
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Település</div>
              <div>
                {{ item?.postCode }}, {{item?.city}}
              </div>
            </div>
            <div class="d-flex px-3 py-2">
              <div class="flex-1-1-0">Cím</div>
              <div>
                {{ item?.address }}
              </div>
            </div>
          </div>
        </div>
        <div class="box-container">
          <h3>Továbbiak</h3>
          <div :class="[isPage ? 'white-box py-3' : 'box-row']">
            <div class="d-flex px-3 py-2">
              <dv class="flex-1-1-0">Fizetve</dv>
              <div>
                {{ date(item?.payeddate) }}
                <input
                  type="date"
                  v-if="showPaymentDate"
                  v-model="paymentDate"
                />
                <button v-if="showPaymentDate" v-on:click="addPaymentDate()">
                  <BaseIcon name="plus" />
                </button>
                <button
                  v-else-if="item?.payeddate < 1"
                  v-on:click="showPaymentDate = true"
                >
                  <BaseIcon name="pencil" />
                </button>
              </div>
            </div>
            <div class="d-flex px-3 py-2" v-if="item?.duedate">
              <div class="flex-1-1-0">Fizetési határidő</div>
              <div>
                {{ date(item?.duedate) }}
              </div>
            </div>
            <div class="d-flex px-3 py-2" v-if="item?.date">
              <div class="flex-1-1-0">Hozzáadva</div>
              <div>
                {{ date(item?.date) }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/hu";
import BaseIcon from "../../components/BaseIcon";

export default {
  name: "FinanceItem",
  components: { BaseIcon },
  data() {
    return {
      idData: null,
      isPage: false,
      item: null,
      invoiceNumber: "",
      paymentDate: "",
      showInvoiceNumber: false,
      showPaymentDate: false,
    };
  },
  props: {
    id: Number,
  },
  created() {
    if (this.id === undefined || this.id === null) {
      this.idData = this.$route.params.id;
      this.isPage = true;
    } else {
      this.idData = this.id;
    }
    this.fetchData(this.idData);
  },
  methods: {
    fetchData(id = null) {
      fetch(process.env.VUE_APP_API + "/finance-details?id=" + id)
        .then((res) => res.json())
        .then((response) => {
          if (response.loginNeed) {
            this.$router.push("/login");
          }
          this.item = response;
        })
        .catch((error) => {
          this.message = error;
        });
    },
    addInvoiceNumber() {
      const data = new FormData();
      data.append("invoiceNumber", this.invoiceNumber);
      data.append("id", this.item.id);

      fetch(process.env.VUE_APP_API + "/add-invoice-number", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            this.showInvoiceNumber = false;
            this.item.invoiceNumber = this.invoiceNumber;
          }
        })
        .catch((error) => {
          this.message = error;
        });
    },
    addPaymentDate() {
      const data = new FormData();
      data.append("paymentDate", this.paymentDate);
      data.append("id", this.item.id);

      fetch(process.env.VUE_APP_API + "/add-payment-date", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            this.showPaymentDate = false;
            this.item.payeddate = response.time;
          }
        })
        .catch((error) => {
          this.message = error;
        });
    },
    month(val) {
      dayjs.extend(localizedFormat);
      dayjs.locale('hu');
      return dayjs.unix(val).format("M");
    },
    year(val) {
      dayjs.extend(localizedFormat);
      dayjs.locale('hu');
      return dayjs.unix(val).format("YYYY");
    },
    date(val) {
      dayjs.extend(localizedFormat);
      dayjs.locale('hu');
      return dayjs.unix(val).format("L");
    },
  },
};
</script>
